body{   
  position:relative;
  overflow-x: hidden;
  font-family: 'Lato', sans-serif;}
  .topnavcolor{
    
    background-color:#eeeeeec4;
  }
  .wi{
    width: 66.7%!important;
  }
  .ali{
    font-size: 12px;
    padding-top: 5px;
}
a {
  color: rgba(0, 0, 0, 0.575);
  text-decoration: none;
}
body::-webkit-scrollbar { width: 0 !important }
.carousel-inner{height: 495px; object-fit: contain

}.cl{background-color: #bec3c778;
}
.s{        font-weight: bolder;
  font-size: x-small;
  letter-spacing: 7px;
  margin: 6px;
}
.navbar-brand{
  color: black!important;
}
.active a{
  color: rgb(0, 0, 0);
}.pad{
  padding: 1.05rem;
}
a:hover{
  color: black!important;
}
.carousel-control-next, .carousel-control-prev{
  width: 6%;
}
.d-md-block {
  display: block!important;
  position: sticky;
  padding: 200px;
}
.fon {
  transition-timing-function: ease;
  font-size: xxx-large;
  font-family: cursive;
  font-weight: 700;

}.fong {
  transition-timing-function: ease;
  font-size: xxx-large;
  font-weight: 700;

}
.subtitle{
  letter-spacing: 1px;
}
.btn-secondary {
  color: #000;
  background-color: #e0e3e5;
}
.cll {
  background-color: #5c7bb7;
  color: white;
}
.iconcolor{
  color: white!important;
}
.btn-primary {
  color: #fff;
  background-color: #5c7bb7;
  border-color: #5c7bb7;
}
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;500;600;700&display=swap");
.container1 {
  height: 100%;
  width: 100%;
  padding: 1rem 0;
  display: grid;
  place-content: center;
}
.container1 .cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
  pointer-events: none;
}
.container1 .card {
  max-width: 15rem;
  color: #ffffffda;
  padding: 1.5rem;
  background-color: #5c7bb7;
    pointer-events: auto;
  transform: scale(1);
  opacity: 1;
  transition: all 150ms ease-in-out;
  display: flex;
  flex-direction: column;
}
.container1 .card .card-title {
  position: relative;
}
.container1 .card .card-title::before {
  content: "";
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  box-shadow: inset 0 0 3px #ffffff8a;
  background-color: transparent;
  position: absolute;
  right: 0;
}
.container1 .card .date {
  color: #e7ebf4;
  font-size: 0.85rem;
  margin-bottom: 1.5rem;
}
.container1 .card .description {
  font-size: 0.9rem;
}
.container1 .card:nth-child(even) {
  transform: translateY(8px);
}
.container1 .card:nth-child(n) {
  transform: rotate(-5deg);
}

.cards:hover > .card:hover {
  transform: scale(1.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.cards:hover > .card:not(:hover) {
  opacity: 0.5;
}
.pt{
  padding-top: 2rem;
  margin-top: 3rem;}
  .xl{
    
      display: inline;
      position: absolute;
      margin-left: 306px;
  
  }
.card-title{
  color:#fff;
}

.backcolor{
  background-color: #5c7bb7;
}
.heart{
  color:red;
}
.white{
  color: rgb(255, 255, 255);
}
.notification{
  color:#fff300
}
.shield{
  color: #084298;
}
.card{
  border: 0px solidrgba(0,0,0,.125);
}



@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);
figure.snip1107 {
  font-family: 'Raleway', Arial, sans-serif;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  margin-left: 0.8rem;
    max-width: 384px;
  max-height: 220px;
  width: 100%;
  background: #000000;
  text-align: center;
}
figure.snip1107 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
figure.snip1107 img {
  opacity: 1;
  width: 100%;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}
figure.snip1107 figcaption {
  position: absolute;
  bottom: 0;
  left: 18%;
  right: 18%;
  height: 100%;
}
figure.snip1107 figcaption > div {
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 50%;
}
figure.snip1107 h5,
figure.snip1107 span {
  margin: 0;
  padding: 10px 15px;
  display: inline-block;
  width: 100%;
  font-weight: 800;
  text-transform: uppercase;
}
figure.snip1107 span {
  background: #ffffff;
  color: #4d4d4d;
  position: relative;
  bottom: 0;
  left: 0;
  position: absolute;
  -webkit-transform: translate3d(0%, 100%, 0);
  transform: translate3d(0%, 100%, 0);
}
figure.snip1107 h5 {
  background: #333333;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
}
figure.snip1107 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  color: #ffffff;
}
figure.snip1107.blue {
  background: #0a212f;
}
figure.snip1107.blue h5 {
  background: #2980b9de;
}
figure.snip1107.red {
  background: #36100c;
}
figure.snip1107.red h5 {
  background: #c0392b;
}
figure.snip1107.yellow {
  background: #583804;
}
figure.snip1107.yellow h5 {
  background: #f39c12;
}
figure.snip1107.navy {
  background: #000000;
}
figure.snip1107.navy h5 {
  background: #34495e;
}
figure.snip1107:hover img,
figure.snip1107.hover img {
  opacity: 0.35;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
figure.snip1107:hover figcaption span,
figure.snip1107.hover figcaption span {
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
}
figure.snip1107:hover figcaption h5,
figure.snip1107.hover figcaption h5 {
  -webkit-transform: translate3d(0%, -100%, 0);
  transform: translate3d(0%, -100%, 0);
}

.t{
  padding: 142px;
}
.size{
  height: 86px;
}
.g{height: 228px;}
.mu{color:white;}
.ic{
  padding: 5px;
  margin-bottom: 9px;
    margin-left: -14px;

}.ici{
  padding: 3px;
  margin-bottom: 5px;
}
.iic {
  padding: 1px;
  margin-bottom: 5px;
}
.ma{
  margin-left: 46px;
}.maa {
  margin-left: 53px;
}
.ll{
  font-size: 2.5rem;
  color: #f9fafb;
  font-family: cursive;
  font-weight: 900;

}
.bcolor{
  color: rgb(255, 255, 255);
  background-color: #084298d9;
}
.bgi {
  width: 100%;
  object-fit: cover;
  height: 219px;
}
.lio{
  
    position: absolute;
    margin-top: 84px;
    color: #ffffff;
    font-weight: bolder;
    margin-left: 29px;
}
.po{
  position: absolute;
  margin-left: 6px;
  margin-top: 10px;
  width: 11rem;
}
.st{
  position: absolute;
  left: 53%;
  width: 35%;
}
.ssd{display: flex;}
.m1{
  margin-left: 4px!important;
}
label {
  display: inline-block;
  margin-left: 4px;
}
.contact{
  width: 10rem;
}
.aku { 
  transition: all .2s ease-in-out; 
  font-weight: bold;
}

.aku:hover {
  transform: scale(1.1); 
  color: #000;
}
.marg{
  margin-left: 52px;
}
.min{
  margin-left: 3.3rem;
}
.margtop{
  margin-top: 35px;
}
.cine{margin-left: 55px;}
.colo{
  background-color:  aliceblue; ;
}
@media screen and (max-width: 600px) {
 .tq{
  padding-bottom: 1.5rem;
}.aku{
  padding-left: 3rem;
  padding-right: 3rem;
  width: 100%;
  padding-bottom: 2rem;
 } .ssd{
  width: auto;
 }
 .st {
  position: relative;padding: 12px;margin-bottom: 2rem;
width: 100%;
left:0%;}
 .fff{
    text-align: center;
  }  .spb{
    flex: auto;
    margin-right: 1.5rem;
  }.cllo{
    visibility: hidden;
    
    display: none;
  }
  .row {
     --bs-gutter-x: 0rem;} .container1 {
  
    display: none;
}.col-3 {
  flex: 0 0 auto;
  width: auto;
}.clla{display: none;}.cll {
    visibility: hidden;
    
    display: none;
  }
  .mbs{
    height: 39rem;
  }
  .carousel-inner{
    height: 41rem;
  }
  .fon{font-size: larger;}
  .carousel-caption{
    padding: 91px;
  }
  .subtitle{margin-bottom: 8rem;}
  .ss{
    margin-bottom: 1rem!important;
  }
.vdo{height: 46%!important;
  padding-top: 0px;
  margin-left: 0px;}
.mt5{ 
  margin-top: -4rem!important;
}.bcolor{
  padding: 0rem!important;
   --bs-gutter-x: 0rem!important;
}.g {
  height: 194px;
}.gik{width: 297px!important;  margin-left: -3px;
}
.kil{width: 297px!important; margin-left: -3px;}
.gif {
  height: 216px;
  width: 290px!important;
  margin-left: 0px;
}.pad{padding:0rem}
.llo{width: 100%;}
.llol{width: 100%;flex: auto;
}.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  right: 10rem;
  bottom: 7rem;
  position: relative;
  list-style: none;
}.mlc{   bottom: 17.2rem;
  position: relative;
}
.x{
  margin: 1rem;
}
.test{padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: xx-large;
}
}

.vdo{height: 80%;}
.mt5{ 
  margin-top: 3rem;
}
.mycard{
  
  position: absolute;
  top: -215px;
  color: white;
  background-color: #5c7bb6;

}
.card1 {
  position: absolute;
  top: -193px;
  background-color: #5c7bb7;
  color: white;
}.card2{
  position: absolute;
  top: -147px;
    background-color: #5c7bb7;
  color: white;
}.card3{
  position: absolute;
  top: -124px;  background-color: #5c7bb7;
  color: white;
}.ch{margin-top: 104px}
.mn{    margin-bottom: 30px;}.gif {
  height: 226px;
  width: 342px;
}
.jh{
  margin-bottom: 0px;
}
.colos{color: #000000;}
.cardh{padding-bottom: 2.5rem;}
.dk{
  color: white;
}
.lkm{
  width: 36%;
}
.baby{
  width: 71%;
}
.model{    width: 63%;
}
.fd{top: 56px;
  position: relative;
  margin-left: 10px;}
  .yt{top: 6px;
    position: relative;}
    



    .ist{
      position: relative;
    top: -12rem;
    z-index: 12;
    background-color: aliceblue;;
    padding-top: 14rem;}
     .sse{background-color:aliceblue;}